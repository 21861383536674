<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--styleguide">

      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{
              name: 'myEntityParameter',
            }"
          />
          <h1 class="page-title">{{ title }}</h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <template v-if="!isLoading">
              <template v-if="'attributuid' in parameter && parameter.attributkey">
                <SelectExtended
                  v-model="parameter.valeur"
                  id="valeur"
                  :optionKey="parameter.attributuid"
                  label="Valeur"
                  :optionValue="parameter.attributlabel"
                  :apiEndpoint="restrictions.length > 0 ? getEndpointRestricted() : `${parameter.endpoint}?sort=${parameter.attributlabel}.ASC`"
                  @update:modelValue="updateValue($event)"
                />
              </template>
              <template v-else>
                <Input
                  id="valeur"
                  label="Valeur"
                  v-model="parameter.valeur"
                  @update:modelValue="updateValue($event)"
                  :input-after="(parameter.precisions && isNaN(parameter.precisions)) ? parameter.precisions : ''"
                  :type="parameter.numerique ? 'number' : 'text'"
                  :options="parameter.numerique ? {step: 0.01,min:0} : {}"

                />
              </template>
          </template>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <Btn
          text="Annuler"
          :to="{ name: 'myEntityParameter' }"
        />
        <Btn
          btnType="submit"
          text="Modifier"
          color="primary"
        />
      </div>
    </div>
  </form>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'MyEntityParameterEditView',

  components: {
    Btn,
    Input,
    Section,
    Container,
    SelectExtended,
  },

  data() {
    const validationSchema = this.yup.object().shape({
      valeur: this.yup.object().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )

    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleItem(values)
    })

    return {
      valeur: null,
      api: {
        endpoint: `entite/${this.$store.state.auth.user.data.entite.id}/parametre`,
      },
      parameters: '',
      parameter: {},
      restrictions: [],
      schema: {},
      onSubmit,
      errors,
      isSubmitting,
      isLoading: true,
    }
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  computed: {
    title() {
      return `${this.pageTitle} - ${this.parameters.designation}`
    },
    isRestricted() {
      return this.restrictions.length > 0
    },
  },

  mounted() {
    this.getParameters()
    this.getEntityParameter()
  },

  methods: {
    updateValue(event) {
      this.parameter.value = event
    },
    handleItem() {
      const { valeur } = this.parameter
      let value = valeur
      try {
        value = JSON.stringify(valeur)
      } catch (e) {
        console.log(e)
      }

      const parameterToArray = [{
        uid: this.parameter.uid,
        valeur: value,
      },
      ]

      this.fetchService.put(this.api.endpoint, {
        parametres: parameterToArray,
      }).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le paramètre a bien été modifié.',
          })

          this.$store.dispatch('app/setData')

          this.$router.push({
            name: 'myEntityParameter',
          })
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
        },
      )
    },

    getParameters() {
      this.fetchService.get(`dictionnaire/parametrage/${this.$route.params.id}`)
        .then((response) => {
          this.parameters = response.data
        })
    },

    getEntityParameter() {
      this.fetchService.get(`entite/${this.$store.state.auth.user.data.entite.id}/parametre`)
        .then((response) => {
          this.parameter = response.data.find(
            (parameter) => parameter.id === Number(this.$route.params.id),
          )
          const { valeur } = this.parameter

          try {
            const pv = JSON.parse(valeur)
            this.parameter.valeur = pv ?? ''
          } catch (error) {
            console.log(error)
          }
          ['NOTATION_ORDRESAISIE', 'NOTATION_CHEMINDENOTATION'].forEach((uid) => {
            if (uid === 'NOTATION_ORDRESAISIE' && !this.parameter.valeur && this.parameter.uid === uid) {
              this.parameter.valeur = {
                key: 'MVOE',
                value: 'Microparcelle / Variable / Organe / Echantillon',
              }
            }
            if (uid === 'NOTATION_CHEMINDENOTATION' && !this.parameter.valeur && this.parameter.uid === uid) {
              this.parameter.valeur = {
                key: 'ARHORIZONTAL',
                value: 'A/R horizontal',
              }
            }
          })

          this.restrictions = this.parameter.restrictionuid !== '[]' ? this.parameter.restrictionuid.split(',') : []
          this.isLoading = false
        })
    },

    getEndpointRestricted() {
      const u = this.restrictions.join(',')
      return `${this.parameter.endpoint}?filters=uid:in(${u})`
    },
  },
}
</script>

<style scoped>

</style>
